import React from "react"
import { Link, graphql } from "gatsby"

import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { rhythm, scale } from "../utils/typography"
import { Helmet } from "react-helmet"

class BlogPostTemplate extends React.Component {
  state = {
    loaded: false,
    chatUrl:
      "https://cdn.jsdelivr.net/gh/drawrowfly/react-chat-widget/dist/widget.790f7559.js",
  }

  handleChangeClientState = (newState, addedTags) => {
    if (addedTags && addedTags.scriptTags) {
      let foundScript = addedTags.scriptTags.find(
        ({ src }) => src === this.state.chatUrl
      )
      if (foundScript) {
        foundScript.addEventListener(
          "load",
          () => this.setState({ loaded: true }),
          { once: true }
        )
      }
    }
  }
  render() {
    const post = this.props.data.markdownRemark
    const siteTitle = this.props.data.site.siteMetadata.title
    const { previous, next } = this.props.pageContext
    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title={post.frontmatter.title}
          description={post.frontmatter.description || post.excerpt}
        />
        <article>
          <header>
            <h1
              style={{
                marginTop: rhythm(1),
                marginBottom: 0,
              }}
            >
              {post.frontmatter.title}
            </h1>
            <p
              style={{
                ...scale(-1 / 5),
                display: `block`,
                marginBottom: rhythm(1),
              }}
            >
              {post.frontmatter.date}
            </p>
            <div id="shit">
              <Helmet onChangeClientState={this.handleChangeClientState}>
                {typeof window !== "undefined" && (
                  <script async defer src={this.state.chatUrl} />
                )}
              </Helmet>
            </div>
          </header>
          <div id="chat-app"></div>
          <section dangerouslySetInnerHTML={{ __html: post.html }} />
          <hr
            style={{
              marginBottom: rhythm(1),
            }}
          />
          <Helmet></Helmet>
          <footer>
            <Bio />
          </footer>
        </article>
        {/* {this.state.loaded &&
            <Helmet >
              <script>{`
                  ReactChatBot.config({
                      clientKey: "123123123",
                      selector: "#chat-app"
                  });
                  ReactChatBot.widgets.myWidget.new().render()
              `}</script>
            </Helmet>
          } */}
        <nav>
          <ul
            style={{
              display: `flex`,
              flexWrap: `wrap`,
              justifyContent: `space-between`,
              listStyle: `none`,
              padding: 0,
            }}
          >
            <li>
              {previous && (
                <Link to={previous.fields.slug} rel="prev">
                  ← {previous.frontmatter.title}
                </Link>
              )}
            </li>
            <li>
              {next && (
                <Link to={next.fields.slug} rel="next">
                  {next.frontmatter.title} →
                </Link>
              )}
            </li>
          </ul>
        </nav>
      </Layout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
      }
    }
  }
`
